import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.css';
import Main from './components/Main';
import MFO from './components/mfo';
import InsuranceAuto from './components/InsuranceAuto';
import InsuranceTravel from './components/InsuranceTravel';


function App() {
  return (
    <BrowserRouter>
    {/* <BrowserRouter basename='https://bestoffer.kz'> */}
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/mfo" element={<MFO />} />
        <Route path="/insurance-auto" element={<InsuranceAuto />} />
        <Route path="/insurance-travel" element={<InsuranceTravel />} />
      </Routes>
    </BrowserRouter>
    
  );
}

export default App;

import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navigation from "../Navigation";
import Footer from "../Footer";
import Faq from "./faq";
import { Box, Grid, TextField, Button, Typography, CardMedia } from "@mui/material";
import Paper from "@mui/material/Paper";
import AspectRatio from "@mui/joy/AspectRatio";
import { Link } from 'react-router-dom';
import {
  DataGrid,
  useGridApiRef,
  DEFAULT_GRID_AUTOSIZE_OPTIONS,
} from '@mui/x-data-grid';
import { randomRating, randomTraderName } from '@mui/x-data-grid-generator';import logo from '../../assets/logo.svg'
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";

import Loader from '../../components/Loading/index.jsx'

import { styled } from "@mui/material/styles";
// import paying from "../../assets/paying.svg"
// import mfo_sample from "../../assets/mfo_sample.svg"
import zaim_sample from '../../components/mfo/mfo_list.json'
import { GetMfo, GetMfoRating } from '../../requests'
import Skeleton from '@mui/material/Skeleton';

export default function Mfo() {
  const navigate = useNavigate();
  const location = useLocation()

  const apiRef = useGridApiRef();
  const [includeHeaders, setIncludeHeaders] = useState(
    DEFAULT_GRID_AUTOSIZE_OPTIONS.includeHeaders,
  );
  const [includeOutliers, setExcludeOutliers] = useState(
    DEFAULT_GRID_AUTOSIZE_OPTIONS.includeOutliers,
  );
  const [outliersFactor, setOutliersFactor] = useState(
    String(DEFAULT_GRID_AUTOSIZE_OPTIONS.outliersFactor),
  );
  const [expand, setExpand] = useState(DEFAULT_GRID_AUTOSIZE_OPTIONS.expand);
  const autosizeOptions = {
      columns: ['name', 'age', 'min_rate', 'min_gesv' ,'sum'],
      includeOutliers: false,
      includeHeaders: true,
      outliersFactor: '2',
      expand: true
  };

  const { message } = location.state || {}
  const finalMessage = message || 'all'
  // const queryParams = new URLSearchParams(location.search)
  // const message  = queryParams.get('message') || 'all'
  // const [zaimy, setZaimy] = useState(zaim_sample)
  // const [rows, setRows] = useState(zaim_sample)
  const [zaimy, setZaimy] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [sum, setSum] = useState(finalMessage)
  
  const columns = [{
    field: 'name',
    headerName: 'МФО',
    // minWidth: '200',
    editable: false,
  }, {
    field: 'Age',
    headerName: 'Возраст',
    sortable: true,
    // minWidth: 160,
    // valueGetter: (value, row) => `от ${row.min_age || ''} до ${row.max_age || ''} лет`,
    valueGetter: (value, row) => `от ${row.min_age || ''} лет`,
  }, {
    field: 'min_rate',
    headerName: 'Ставка',
    // minWidth: 150,
    editable: false,
    valueGetter: (value, row) => `от ${row.min_rate || ''}%`,

  }, {
    field: 'min_gesv',
    headerName: 'ГЭСВ',
    // minWidth: 150,
    editable: false,
    valueGetter: (value, row) => `от ${row.min_gesv || ''}%`,

  }, {
    field: 'sum',
    headerName: 'Сумма',
    sortable: true,
    minWidth: 300,
    valueGetter: (value, row) => `от ${row.min_sum.toLocaleString('en', { useGrouping: true}).replace(/,/g,' ') || ''} до ${row.max_sum.toLocaleString('en', { useGrouping: true}).replace(/,/g,' ') || ''} тг`,
  },
  ];

  useEffect(() => {
    async function MfoListGet() {
      const response = await GetMfo(finalMessage);
      setZaimy(response);
      
    } 
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    MfoListGet();
  }, [])

  async function handleFilter(val){
    const response = await GetMfo(val);
    setZaimy(response);
    setIsLoading(false)
  }
  
  return (
    <>
      <Navigation />
      <div className="zaim">
      <Box sx={{ margin: "1%", marginLeft: '13%' }}>
        <br></br>
        <h2>Лучшие займы онлайн</h2>
      </Box>
      
      <Grid container spacing={{ xs: 'auto', md: 3 }} direction="row" alignItems="center" justifyContent="center">
        <div className="campaign_white">
          <Grid container direction="row" spacing={1}>
          <Grid xs={5} sm={7} >
              <TextField
                placeholder="Введите сумму"
                variant="outlined"
                type = 'number'
                sx={{
                    width: {xs:'85%', sm:'95%'},
                    margin: {xs:'19% auto', sm:'5%'},
                    marginRight:'0%' 
                }}
                // sx={{ width: '95%', margin: '5%', marginRight:'0%' }}
                size='small'
                inputProps={{style: {fontSize: 13}}} // font size of input text
                InputLabelProps={{style: {fontSize: 12}}} // font size of input label
                value = {sum || 'all'}
                onChange={(e) => {
                  setSum(e.target.value);
                }}
              />
            </Grid>
            {/* <Grid xs="auto">
              <TextField
                id="outlined-select-currency"
                select
                label="Укажите срок"
                sx={{ maxWidth: 250, minWidth: 200, margin: '5%', marginLeft: '15%' }}
                size='small'
                inputProps={{style: {fontSize: 13}}} // font size of input text
                InputLabelProps={{style: {fontSize: 12}}} // font size of input label
              // defaultValue="EUR"
              // helperText="Please select your currency"
              >
                {currencies.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                            </MenuItem>
                                        ))}
              </TextField>
            </Grid> */}
            <Grid xs={4} sm={3} >
              <Button
                sx={{ width: '85%', fontSize: 11, margin: '12%', marginLeft:'9%',
                   marginRight:'0%', minHeight: 35, backgroundColor: '#2B5BA4' }}
                // sx={{
                //     width: {xs:'90%', sm:'85%'},
                //     // margin: {xs:'5% auto', sm:'5%'},
                //     margin: '12%', marginLeft:'9%',
                //     marginRight:'0%',
                //     minHeight: 35, backgroundColor: '#2B5BA4',
                //     fontSize: 11,
                // }}
                variant="contained"
                onClick = {(e) => {setIsLoading(true);handleFilter(sum)}}>Показать предложения</Button>
            </Grid>
            <Grid xs={3} sm={2}>
              <Button
                sx={{ width: '85%', fontSize: 11, margin: '18%', marginLeft:'0%', 
                  minHeight: 35, backgroundColor: '#2B5BA4' }}
                // sx={{
                //     width: {xs:'70%', sm:'85%'},
                //     // margin: {xs:'5% auto', sm:'5%'},
                //     margin: '18%', marginLeft:'9%',
                //     marginRight:'0%',
                //     minHeight: 35, backgroundColor: '#2B5BA4',
                //     fontSize: 11,
                // }}
                variant="contained"
                onClick = {(e) => { setSum('all');setIsLoading(true); handleFilter('all')}}>Сбросить</Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
      {/* <div className="zaim"> */}
      <Box sx={{width: '90%', marginRight: '3%', marginLeft: '10%', marginTop: '6%'}} >
            <Grid container direction="row" spacing={{ xs: 'auto', md: 3 }}>
            <Grid xs={8} >
              <p>Подобрано {zaimy?.length} предложений</p>
            </Grid>
            <Grid xs={4} >
            </Grid>
            </Grid>
            </Box>
            {/* { (isLoading) ? <Loader /> : */}
            {(isLoading) ? 
              <div className="gridCard">
                  { Array.from(
                      {length: 8},
                      (_, index) => index + 1
                    ).map((x,index) => (
                    <div key={index} className="card">
                      <Card
                        orientation="horizontal"
                        variant="outlined"
                        sx={{
                          width: 'auto',
                          minHeight: 130,
                          maxHeight: 300,
                          marginLeft: "auto",
                          marginRight: "8%",
                          marginTop: "6%",
                          marginBottom: "7%",
                          borderRadius: "20px",
                          gap: "0 !important",
                          padding: '10%',
                          backgroundColor: 'white'
                        }}
                      >
                        <CardOverflow>
                          <AspectRatio
                            ratio="1"
                            sx={{
                              // backgroundColor: "white",
                              width: 60,
                              borderRadius: 20,
                              float: "right",
                              padding: "10px",
                            }}
                          >
                          <Skeleton sx={{ height: 190 }} animation="wave" variant="rectangular" />
                          </AspectRatio>
                        </CardOverflow>
                        <CardContent sx={{  textAlign: "left",  }}>
                          <>
                            <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                            <Skeleton animation="wave" height={10} width="80%" />
                            <Skeleton animation="wave" height={10} width="80%" />
                          </>
                        </CardContent>
                      </Card>
                    </div>
                  ))}
                </div>
                 :
                <>
                <div className="gridCard">
                  {zaimy?.map((item, index) => (
                    <div key={index} className="card">
                      <Card
                        orientation="horizontal"
                        variant="outlined"
                        //   onClick={(e) => navigate('/find-internship')}
                        sx={{
                          // width: { xs: "100%", sm:'400px'},
                          minHeight: 130,
                          maxHeight: 300,
                          maxWidth: 800,
                          // minWidth: 150,
                          margin: "10px auto",
                          // marginLeft: "auto",
                          marginRight: "8%",
                          // marginRight: {xs:'8% auto', sm:'8%'},
                          // marginTop: "6%",
                          // marginBottom: "7%",
                          borderRadius: "20px",
                          padding: 2,
                          backgroundColor: 'white'
                        }}
                      >
                        <CardOverflow>
                          <CardMedia
                          component="img"
                          image={item.logo}
                          alt='icon'
                          sx={{
                            width: {xs:'30px', sm:'50px'},
                            height: {xs:'30px', sm:'50px'},
                            margin: {xs:'10px auto', sm:'10px'},
                            borderRadius: 20,

                          }}
                          />
                          {/* <AspectRatio
                            ratio="1"
                            sx={{
                              // backgroundColor: "white",
                              width: 60,
                              borderRadius: 20,
                              float: "right",
                              padding: "10px",
                            }}
                          >
                            <img
                              src={item.logo}
                              style={{
                                background: "white",
                                padding: "15px",
                                height: "auto",
                              }}
                            />
                          </AspectRatio> */}
                        </CardOverflow>
                        <CardContent sx={{  textAlign: "left",  }}>
                          <Typography fontWeight="md" mb={1} fontSize={16}>
                            {item?.name}
                          </Typography>
                          <Typography fontSize={10}>
                            <b> Сумма: </b> {' от '}{item?.min_sum.toLocaleString('en', { useGrouping: true}).replace(/,/g,' ')} - {item?.max_sum.toLocaleString('en', { useGrouping: true}).replace(/,/g,' ') } {'тг'}
                          </Typography>
                          <Typography level="subtitle2" fontSize={10}>
                            <b> Возраст: </b> {' от '}{item?.min_age} {'лет'}
                          </Typography>
                          <Button
                            sx={{ marginTop: '4%', marginBottom: '2%', backgroundColor: '#2B5BA4', borderRadius: 2, fontSize: 7 }}
                            variant="contained" > <a style={{ 'color': 'white', fontSize: 10 }} href={item.site} target="_blank" rel="noopener noreferrer">Перейти на сайт</a>
                          </Button>
                        </CardContent>
                        {/* <CardActions> */}
                        
                        {/* </CardActions> */}
                        
                      </Card>
                    </div>
                  ))}
                </div>
                <div className="table">
                  <div style={{
                  width: '90%', margin:'5%'
                  // display: 'flex',
                  // justifyContent: 'center', alignItems: 'center'
                    }}>
                  {/* <Box
                    sx={{
                      // display: 'flex',
                      // justifyContent: 'center',
                      // alignItems: 'center',
                      m: '1%',
                      bgcolor: 'background.paper',
                      borderRadius: 5,
                    }}
                  > */}
                    <DataGrid
                      rows={zaimy}
                      columns={columns}
                      getRowId={(row) => row.name + row.mfo_id}
                      sx={{ p: 3, borderRadius: 5 }}
                      // apiRef={apiRef}
          // density="compact"
          // autosizeOptions={{
          //   columns: ['name', 'age', 'min_rate', 'min_gesv', 'sum'],
          //   includeOutliers: true,
          //   includeHeaders: true,
          //   expand:true
          // }}
          autosizeOptions={autosizeOptions}
                    // initialState={{
                    //   pagination: {
                    //     paginationModel: {
                    //       pageSize: 5,
                    //     },
                    //   },
                    // }}
                    // sx={{ width:'70%' }}
                    // pageSizeOptions={[5]}
                    />
                  {/* </Box> */}
                  </div>
                </div>
                </>
} 
                <div className="faq">
                  <Faq />
                </div>
      </div>
      <Footer />
    </>
  )
}
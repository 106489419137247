import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../Navigation";
import Footer from "../Footer";
import { Typography, Box, Grid } from "@mui/material";
import Input from "@mui/joy/Input";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import { makeStyles } from "@material-ui/core/styles";
import paying from "../../assets/paying.svg"
import car_ins from "../../assets/car-insurance.svg"
import sample_banner from "../../assets/sample_banner.png"
import categorie from "./categories.json"

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.8),
    borderRadius: theme.spacing(0),
    
    "& :hover": {
      backgroundColor: "#2b5ba4"
    }
  },
  media: {
    height: 140
  }
}));


export default function Main() {
  const navigate = useNavigate();
  const classes = useStyles();

  const [categories, setCategories] = useState(categorie)
  const [sum, setSum] = useState()

  function calc() {
    const data = { message: sum};
    console.log('data', data)
    navigate(`/mfo`, { state: data})
  }


  return (
    <>
      <Navigation />
      <Box sx={{ margin: "2%", marginLeft: '8%', marginRight: '8%' }}>
        <img src={sample_banner} width={'100%'} />
      </Box>
      <Box sx={{ margin: "2%", marginLeft: "10%" }}>
        <Grid container spacing={2}>
          <Grid xs={5} >
            <div className="campaign">
              <Grid container spacing={2}>
                <Grid xs={2} sx={{ margin: "6%" }}>
                  <img className="icon_prod " src={paying} height={50} />
                </Grid>
                <Grid xs={7}>
                  <div classname="card_offer">
                    <h3>Выберите микрокредит</h3>
                    <p>Получите выгодное предложениe</p>
                  </div>
                  <div>
                    <Input
                      endDecorator={<button className="btn btn_calc_mfo" onClick={calc}>Рассчитать</button>}
                      sx={{
                        "--Input-focusedThickness": "2px",
                        "--Input-placeholderOpacity": 0.5,
                        "--Input-gap": "9px",
                        "--Input-radius": "12px",
                        marginTop: "5px",
                        marginBottom: "10px",
                        marginRight: "5px",
                        alignSelf: "center",
                        backgroundColor:'white'
                      }}
                      size="sm"
                      placeholder="Введите сумму"
                      onChange={(e) => {setSum(e.target.value)}}
                    />
                  </div>
                </Grid>

              </Grid>

            </div>
          </Grid>
          <Grid xs={1}></Grid>
          <Grid xs={5} >
            <div className="campaign">
              <Grid container spacing={2}>
                <Grid xs={2} sx={{ margin: "6%" }}>
                  <img className="icon_prod " src={car_ins} height={50} />
                </Grid>
                <Grid xs={7}>
                  <div classname="card_offer">
                    <h3>Выгодное автострахование</h3>
                    <p>Получите выгодное предложение</p>
                  </div>
                  <div><button className="btn btn_calc_auto" >Рассчитать</button></div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>
      <div className="zaim">
        <div className="categories">
          {categories?.map((item, index) => (
            <div key={index} className={classes.root}>
              <Card
                // className={classes.root}
                orientation="horizontal"
                variant="outlined"

                onClick={(e) => navigate(`/${item.page}`)}
                sx={{
                  width: 200,
                  height: 80,
                  // marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "10px",
                  marginBottom: "10px",
                  borderRadius: "20px",
                  gap: "0 !important",
                  backgroundColor:'white'
                }}
              >
                <CardContent sx={{ px: 1, textAlign: "left", paddingLeft: "0" }}>
                  <Typography fontWeight="medium" mb={1} variant="subtitle2" >
                    {item?.name}
                  </Typography>

                </CardContent>
                <CardOverflow>
                  {/* <AspectRatio
                  ratio="1"
                  sx={{
                    // backgroundColor: "white",
                    width: 80,
                    borderRadius: 20,
                    float: "right",
                    padding: "10px",
                  }}
                > */}
                  <img
                    src={item.logo}
                    style={{
                      background: "transparent",
                      padding: "8px",
                      height: "auto",
                      marginLeft: '-7%'
                    }}
                  />
                  {/* </AspectRatio> */}
                </CardOverflow>

              </Card>
            </div>
          ))}
        </div>
      </div>

      <Footer />
    </>
  )
}

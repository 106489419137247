import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navigation from "../Navigation";
import Footer from "../Footer";
import { Typography, Box, Grid } from "@mui/material";
import Input from "@mui/joy/Input";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import { makeStyles } from "@material-ui/core/styles";
import paying from "../../assets/paying.svg"
import car_ins from "../../assets/car-insurance.svg"
import sample_banner from "../../assets/sample_banner.png"
// import categorie from "./categories.json"
import TextField from '@mui/material/TextField';

const useStyles = makeStyles((theme) => ({
  root: {
    // maxWidth: 345,
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.8),
    borderRadius: theme.spacing(0),
    
    "& :hover": {
      backgroundColor: "#2b5ba4"
    }
  },
  media: {
    height: 140
  }
}));


export default function InsuranceAuto() {
  const navigate = useNavigate();
  const classes = useStyles();

  const [categories, setCategories] = useState()

  function calc() {
    navigate('/Zaim')
  }
  return (
    <>
      <Navigation />
      <Box sx={{ margin: "2%", marginLeft: '8%', marginRight: '8%' }}>
        <img src={sample_banner} width={'100%'} />
      </Box>
      <div style={{marginLeft: '8%', marginRight: '8%' }}>

        <form>
        <TextField id="outlined-basic" label="Outlined" variant="outlined" />
          <input type="text" name="iin" placeholder="Введите ИИН" />
          <input type="text" name="register-number" placeholder="Введите номер транспорта" />
          <input type="text" name="phone" placeholder="Введите номер телефона" />
          <select>
            <option>12 месяцев</option>
          </select>
          <input type="submit" />
        </form>

      </div>

      <Footer />
    </>
  )
}

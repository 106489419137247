import { Accordion,AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';


export default function Faq() {
    return (
        <>
        <div style={{width: '90%', marginTop: '2%'}}>
        <h2 style={{textAlign:'left'}}>Что важно знать о микрокредитах </h2>
        <Accordion className="custom-accordition" >
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          className="custom-accordition-summary"
        >
          <Typography><b>Как правильно взять займ на выгодных условиях?</b></Typography>
        </AccordionSummary>
        <AccordionDetails className="custom-accordition-details">
          <Typography>
          Прежде чем оформить микрозаем, убедитесь, что микрофинансовая организация (МФО) зарегистрирована в специальном реестре (на сайте Национального банка либо data.egov.kz). На рынке существует множество нелегальных компаний, которых следует избегать.
          <br></br>
          <br></br>
          Хотя максимальные процентные ставки регулируются законом, минимальные ставки могут существенно различаться. Поэтому рекомендуется сравнить предложения нескольких МФО перед принятием решения. Это можно сделать с помощью нашего онлайн-калькулятора микрозаймов.
          <br></br>
          <br></br>
          Если вы берете срочный микрозаем впервые и вам нужен небольшой заем от 7 до 10 дней, то можно найти множество предложений с нулевой процентной ставкой. Если вы уже пользовались микрокредитами, то стоит обратиться в ту же МФО — некоторые из них предлагают льготные условия для постоянных клиентов.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="custom-accordition">
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          className="custom-accordition-summary"
        >
          <Typography><b>Почему некоторые займы можно оформить под 0%?</b></Typography>
        </AccordionSummary>
        <AccordionDetails className="custom-accordition-details">
          <Typography>
          МФО предлагают займы под 0% для привлечения новых клиентов, многие из которых впоследствии становятся постоянными. Беспроцентные займы обычно выдаются на очень короткий срок, например, на 7–10 дней, и только один раз. Выплачивать их нужно сразу, а не по частям, как в случае с обычным кредитом. Однако будьте внимательны: при оформлении такого займа вам могут предложить дополнительные платные услуги, такие как страховка.
          </Typography>
        </AccordionDetails>
      </Accordion> 
      <Accordion className="custom-accordition">
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          className="custom-accordition-summary"
        >
          <Typography><b> Сколько и на какой срок можно получить займ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails className="custom-accordition-details">
          <Typography>
          Максимальная сумма займа ограничена законом и не может превышать 8 000 МРП, но по файту суммы намного меньше. В основном, быстрые микрокредиты составляют от 10 000 до 184 000 тенге.
          <br></br>
          <br></br>
          Займы обычно выдаются на короткий срок — от 5 до 30 дней. Однако для крупных микрокредитов предусмотрены более длительные сроки.
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="custom-accordition">
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          className="custom-accordition-summary"
        >
          <Typography><b>Что нужно от заёмщика?</b></Typography>
        </AccordionSummary>
        <AccordionDetails className="custom-accordition-details">
          <Typography>
          Для небольшого кредита заёмщику достаточно оформить займ онлайн, предоставив удостоверение личности (в редких случаях требуют дополнительные документы). Справки о доходах не требуются, но необходимо иметь работу, позволяющую вернуть долг (в заявке указывается место работы). Часто заёмщиков просят указать номера телефонов двух других лиц, которые могут подтвердить их платёжеспособность или помочь найти их в случае необходимости.          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="custom-accordition">
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          className="custom-accordition-summary"
        >
          <Typography><b>Как можно погашать долг?</b></Typography>
        </AccordionSummary>
        <AccordionDetails className="custom-accordition-details">
          <Typography>
          Наиболее распространённый способ оплаты займа — с банковской карты через личный кабинет на сайте МФО. Также можно использовать электронные кошельки, салоны связи, банковские реквизиты для оплаты через банк или банкомат, и пункты переводов. За некоторые способы оплаты может взиматься комиссия. Все доступные методы погашения и условия можно найти в договоре или на сайте МФО.          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion className="custom-accordition">
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          className="custom-accordition-summary"
        >
          <Typography><b>Что делать если не получается погасить займ?</b></Typography>
        </AccordionSummary>
        <AccordionDetails className="custom-accordition-details">
          <Typography>
          Сначала свяжитесь с компанией и сообщите о своей сложной финансовой ситуации. Возможно, МФО предоставит временную отсрочку, так как для кредитора выгоднее восстановить график платежей, чем обращаться в суд.
          <br></br>
          <br></br>
          Попросите помощи у родных и друзей, которые могут одолжить вам необходимую сумму для погашения долгов. Другие варианты включают поиск дополнительной работы, продажу имущества или процедуру банкротства. Однако для банкротства потребуются средства на оплату услуг финансового управляющего, что составляет 25 000 рублей за каждую процедуру
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
        </>
    )}
import axios from 'axios'

// const apiUrl ='http://5.35.104.28:8000'
const apiUrl ='https://bestoffer.kz/api'

export async function GetMfo(value){
    if (value == 'all'){try { 
        const response = await axios({
            method: 'GET',
            url: `${apiUrl}/mfo`,
            headers: { 
                'accept': 'application/json', 
                'Content-Type': 'application/json', 
                'Access-Control-Allow-Credentials': true, 
            },
            withCredentials: true
        }); 
        const result = response.data; 
        return result; 
 
    } catch (err) { 
        console.log(err); 
    } }
    else{
    try { 
        const response = await axios({
            method: 'GET',
            url: `${apiUrl}/mfo?loan_sum=${value}`,
            headers: { 
                'accept': 'application/json', 
                'Content-Type': 'application/json', 
                'Access-Control-Allow-Credentials': true, 
            },
            withCredentials: true
        }); 
        const result = response.data; 
        return result; 
 
    } catch (err) { 
        console.log(err); 
    } }
}


export async function GetMfoRating(){
    try { 
        const response = await axios({
            method: 'GET',
            url: `${apiUrl}/mfo/rating`,
            headers: { 
                'accept': 'application/json', 
                'Content-Type': 'application/json', 
                // 'Access-Control-Allow-Credentials': true, 
                // 'Access-Control-Allow-Origin': '*' 
            },
            withCredentials: true
        }); 
        const result = response.data; 
        return result; 
 
    } catch (err) { 
        console.log(err); 
    } 
}
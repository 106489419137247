import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import logo from "../../assets/logo.png";
import "./nav.css";
import { Button, Typography } from "@mui/material";
import { IoClose, IoMenu } from "react-icons/io5";

export default function Navigation() {
  const navigate = useNavigate();

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
 
   const closeMenuOnMobile = () => {
     if (window.innerWidth <= 1150) {
       setShowMenu(false);
     }
   };

  return (
    <>
    <div className="nav-top">
      <div>
        <Link to={"/"}>
          <img src={logo} className="logo" alt="eclipse" height={"100px"} />
        </Link>
      </div>
      <div className="nav_link" onClick={closeMenuOnMobile}>
        {/* <Link to="/">Страхование</Link> */}
        <Link to="/insurance-auto">Автострахование</Link>
      </div>
    
       <div className="nav_link"  > 
        <Link to="/insurance-travel">Cтрахование туристов</Link>
      </div>
      <div className="nav_link"  > 
        <Link to='/mfo' >Займы</Link>
      </div>
      {/* <div className="nav_link">
        <Link to='/'>Бизнес</Link>
      </div> */}
      {/* <div className="nav_link">
        <Link to='/'>Новости</Link>
      </div> */}
      
    </div>

    </>
  );
}

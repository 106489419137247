import { Link } from "react-router-dom";
import logo from "../../assets/logo_noback.png";
import mail from "../../assets/email.svg";
import "./footer.css";

export default function Footer() {
  return (
    <div className="footer">
      {/* <div className="nav-bottom"> */}
        <div>
          <Link to={"/"}>
            <img src={logo} className="logo" alt="eclipse" height={"120px"} />
          </Link>
        </div>
      {/* </div> */}
      <div className="footer_display">
        <div>
        <h3>О Проекте:</h3> 
        <p> 
         BestOffer.kz — это незаменимый финансовый помощник, 
         который помогает вам сделать лучший выбор среди финансовых 
         и страховых продуктов. Платформа предлагает широкий
          ассортимент предложений от ведущих компаний, обеспечивая удобный поиск, 
          сравнение и оформление продуктов онлайн. С нами вы экономите время и деньги,
           находя самые выгодные условия. Доверьтесь BestOffer.kz 
           и делайте осознанные финансовые решения с уверенностью.
        </p>
        </div>
        <div></div>
        <div>
        <h3>Свяжитесь с нами:</h3>
        <p><img src={mail} className="icons" alt="eclipse" height={"30px"}/>boffer.kz@gmail.com</p>
        </div>
      </div>
      <hr />
      <p> © Финансовый маркетплейс BestOffer.kz</p>
    </div>
  );
}
